import "./App.css";
import { useEffect } from "react";

function App() {
  //* ******************** Function to read cookies --- START ******************** *//
  function readCookie(name: any) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  //* ******************** Function to read cookies --- END ******************** *//

  const globalTokenCookie = readCookie("globalToken");

  useEffect(() => {
    // Listen for Authentication requests from Shopper App
    const handleMessage = (event: any) => {
      console.log("*** Event origin ***", event.origin);
      //! restrict only for known merchant domains 👇🏼
      // if (event.origin !== "https://s-wl-dev.adup.io") return;

      const { action, key, value } = event.data;

      // send global token to the Shopper App
      if (action === "GET_GLOBAL_TOKEN") {
        console.log("*** Global token request initiated ***");
        const globalTokenCookie = readCookie(key);
        if (
          globalTokenCookie &&
          globalTokenCookie !== "null" &&
          globalTokenCookie !== ""
        ) {
          const message = {
            action: "RECEIVE_GLOBAL_TOKEN",
            key: key,
            value: globalTokenCookie,
          };
          event.source?.postMessage(message, event.origin);
          console.log("*** Global token shared successfully ***");
        } else {
          const message = {
            action: "RECEIVE_GLOBAL_TOKEN",
            key: key,
            value: "",
          };
          event.source?.postMessage(message, event.origin);
          console.log("*** Global token is not available ***");
        }
      }

      // set global token from the Shopper App
      if (action === "SET_GLOBAL_TOKEN") {
        if (value && value !== "") {
          document.cookie = `${key}=${value}; domain=${window.location.host}; SameSite=None; Secure`;

          const globalTokenCookie = readCookie(key);
          console.log("*** Global token changed ***", globalTokenCookie);
        }
      }

      // revoke global token
      if (action === "REVOKE_GLOBAL_TOKEN") {
        document.cookie = `${key}=${"null"}; domain=${
          window.location.host
        }; SameSite=None; Secure`;

        console.log("*** Global token revoked ***");
      }
    };

    // Register the event listener for 'message' events
    window.addEventListener("message", handleMessage);

    // Specify how to clean up after this effect
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <center>
          <h1>AdUp</h1>
          {globalTokenCookie &&
          globalTokenCookie !== "null" &&
          globalTokenCookie !== "" ? (
            <h4>Successfully Signed In</h4>
          ) : (
            <h4>Signing In...</h4>
          )}
        </center>
      </header>
    </div>
  );
}

export default App;
